<template>
  <section>
    <customers-main />
  </section>
</template>

<script>
export default {
  name: 'Customers',
  components: {
    CustomersMain: () => import('@/components/Customers/CustomersMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
